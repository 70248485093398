<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Paquetes Documentos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Basico</li>
                  <li class="breadcrumb-item active">Documentación</li>
                  <li class="breadcrumb-item active">Paquetes Documentos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_paquete_export"
                        v-if="$store.getters.can('admin.paquetes.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="$store.getters.can('admin.paquetes.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        Nombre<input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Linea De Negocio
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.linea_negocio"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="linea_negocio in listasForms.lineas_negocio"
                            :key="linea_negocio.id"
                            :value="linea_negocio.id"
                          >
                            {{ linea_negocio.nombre }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Entidad
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.entidad"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="entidad in listasForms.entidades"
                            :key="entidad.numeracion"
                            :value="entidad.cod_alterno"
                          >
                            {{ entidad.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in paquetes.data" :key="item.id">
                      <td>{{ item.nombre }}</td>
                      <td>{{ item.linea_negocio.nombre }}</td>
                      <td>{{ item.nEntidad }}</td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="$store.getters.can('admin.paquetes.show')"
                            @click="edit(item)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="$store.getters.can('admin.paquetes.delete')"
                            @click="destroy(item.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="paquetes.total">
                  <p>
                    Mostrando del <b>{{ paquetes.from }}</b> al
                    <b>{{ paquetes.to }}</b> de un total:
                    <b>{{ paquetes.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="paquetes"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <PaqueteDocumentoExport ref="PaqueteDocumentoExport" />
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import PaqueteDocumentoExport from "./PaqueteDocumentoExport";
export default {
  name: "Paquetes",
  components: {
    pagination,
    PaqueteDocumentoExport,
  },
  data() {
    return {
      filtros: {
        nombre: null,
        linea_negocio: null,
        entidad: null,
      },
      paquetes: {},
      listasForms: {
        lineas_negocio: [],
        entidades: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      axios
        .get("/api/admin/paquetes?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.paquetes = response.data;
        });
    },

    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    getEntidades() {
      axios.get("/api/lista/5").then((response) => {
        this.listasForms.entidades = response.data;
      });
    },

    destroy(idPaqueteDoc) {
      this.$swal({
        title: "Esta seguro de eliminar este Paquete de Documentos?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.delete("/api/admin/paquetes/" + idPaqueteDoc).then(() => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino el Paquete exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    create() {
      return this.$router.push({
        name: "/Admin/PaqueteDocumentoForm",
        params: { accion: "Crear" },
      });
    },

    edit(PaqueteDoc) {
      return this.$router.push({
        name: "/Admin/PaqueteDocumentoForm",
        params: { accion: "Editar", data_edit: PaqueteDoc, id: PaqueteDoc.id },
      });
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },

  mounted() {
    this.getIndex();
    this.getLineasNegocio();
    this.getEntidades();
    this.getMsg();
  },
};
</script>